/* src\lib\ExpandButton.svelte generated by Svelte v3.46.3 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	svg_element
} from "../../_snowpack/pkg/svelte/internal.js";

import { createEventDispatcher } from '../../_snowpack/pkg/svelte.js';

function create_else_block(ctx) {
	let svg;
	let path;

	return {
		c() {
			svg = svg_element("svg");
			path = svg_element("path");
			attr(path, "stroke-linecap", "round");
			attr(path, "stroke-linejoin", "round");
			attr(path, "stroke-width", "2");
			attr(path, "d", "M5 15l7-7 7 7");
			attr(svg, "class", "w-6 h-6");
			attr(svg, "fill", "none");
			attr(svg, "stroke", "currentColor");
			attr(svg, "viewBox", "0 0 24 24");
			attr(svg, "xmlns", "http://www.w3.org/2000/svg");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, path);
		},
		d(detaching) {
			if (detaching) detach(svg);
		}
	};
}

// (10:2) {#if !open}
function create_if_block(ctx) {
	let svg;
	let path;

	return {
		c() {
			svg = svg_element("svg");
			path = svg_element("path");
			attr(path, "stroke-linecap", "round");
			attr(path, "stroke-linejoin", "round");
			attr(path, "stroke-width", "2");
			attr(path, "d", "M19 9l-7 7-7-7");
			attr(svg, "class", "w-6 h-6");
			attr(svg, "fill", "none");
			attr(svg, "stroke", "currentColor");
			attr(svg, "viewBox", "0 0 24 24");
			attr(svg, "xmlns", "http://www.w3.org/2000/svg");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, path);
		},
		d(detaching) {
			if (detaching) detach(svg);
		}
	};
}

function create_fragment(ctx) {
	let button;
	let mounted;
	let dispose;

	function select_block_type(ctx, dirty) {
		if (!/*open*/ ctx[0]) return create_if_block;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	return {
		c() {
			button = element("button");
			if_block.c();
			attr(button, "class", "cursor-pointer");
		},
		m(target, anchor) {
			insert(target, button, anchor);
			if_block.m(button, null);

			if (!mounted) {
				dispose = listen(button, "click", /*onClick*/ ctx[1]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (current_block_type !== (current_block_type = select_block_type(ctx, dirty))) {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(button, null);
				}
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(button);
			if_block.d();
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { open } = $$props;
	const dispatch = createEventDispatcher();

	const onClick = () => {
		dispatch('toggle');
	};

	$$self.$$set = $$props => {
		if ('open' in $$props) $$invalidate(0, open = $$props.open);
	};

	return [open, onClick];
}

class ExpandButton extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { open: 0 });
	}
}

export default ExpandButton;