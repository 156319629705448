import {writable} from "../../_snowpack/pkg/svelte/store.js";
import {query, collection, where, getDocs} from "../../_snowpack/pkg/firebase/firestore.js";
import {firestore} from "../firebase.js";
import {TimeHandler} from "./TimeHandler.js";
let internalCourses = [];
const timeHandler = new TimeHandler(3);
const courses = writable([]);
export const loading = writable(false);
let unsubscribeInternal = courses.subscribe((_courses) => {
  internalCourses = _courses;
});
export default courses;
const _updateCourses = (id, mapper) => {
  let ids;
  if (typeof id === "string") {
    ids = new Set();
    ids.add(id);
  } else if (Array.isArray(id)) {
    ids = new Set(id);
  } else {
    ids = id;
  }
  courses.update((_courses) => _courses.map((_course) => ids.has(_course.id) ? mapper(_course) : _course));
};
export const select = (id) => {
  const course = internalCourses.find((_course) => _course.id === id);
  if (!course || course.disabled)
    return;
  if (!course.selected) {
    const selectedDays = timeHandler.getSelectedDays();
    const {canBeSelected, relatedCourses} = canCourseBeSelected(course, selectedDays);
    if (!canBeSelected || !relatedCourses)
      return;
    const times = [];
    while (times.length < 3)
      times.push([]);
    const idsToSelect = [];
    for (const relatedCourse of relatedCourses) {
      const {trimester, timeOfOccurrence} = relatedCourse;
      idsToSelect.push(relatedCourse.id);
      if (!trimester || !trimester.length)
        continue;
      if (trimester.length === 1) {
        const [_trim] = trimester;
        const trim = _trim - 1;
        times[trim] = [...times[trim], ...timeOfOccurrence];
      } else if (trimester.length === 2) {
        const [_start, _end] = trimester;
        const start = _start - 1;
        const end = _end - 1;
        for (let i = start; i < end; i++) {
          times[i] = [...times[i], ...timeOfOccurrence];
        }
      }
    }
    for (let i = 0; i < times.length; i++)
      timeHandler.updateSlot(i, times[i], true);
    _updateCourses(idsToSelect, (_course) => {
      _course.selected = true;
      return _course;
    });
  } else {
    const {relation} = course;
    const relatedCourses = internalCourses.filter((_course) => _course.relation && _course.relation.length && _course.relation === relation);
    if (!relatedCourses.length)
      relatedCourses.push(course);
    const times = [];
    while (times.length < 3)
      times.push([]);
    const idsToDeselect = [];
    for (const relatedCourse of relatedCourses) {
      const {trimester, timeOfOccurrence} = relatedCourse;
      idsToDeselect.push(relatedCourse.id);
      if (!trimester || !trimester.length)
        continue;
      if (trimester.length === 1) {
        const [_trim] = trimester;
        const trim = _trim - 1;
        times[trim] = [...times[trim], ...timeOfOccurrence];
      } else if (trimester.length === 2) {
        const [_start, _end] = trimester;
        const start = _start - 1;
        const end = _end - 1;
        for (let i = start; i < end; i++) {
          times[i] = [...times[i], ...timeOfOccurrence];
        }
      }
    }
    for (let i = 0; i < times.length; i++)
      timeHandler.updateSlot(i, times[i], false);
    _updateCourses(idsToDeselect, (_course) => {
      _course.selected = false;
      return _course;
    });
  }
};
export const canCourseBeSelected = (course, _selectedDays) => {
  const {relation} = course;
  const selectedDays = _selectedDays || timeHandler.getSelectedDays();
  let coursesToCheck = [];
  if (relation && relation.length) {
    coursesToCheck = internalCourses.filter((_course) => _course.relation && _course.relation.length && _course.relation === relation);
  } else {
    coursesToCheck.push(course);
  }
  for (const courseToCheck of coursesToCheck) {
    const {trimester, timeOfOccurrence} = courseToCheck;
    if (!trimester || !trimester.length)
      continue;
    const trimesterstoCheck = [];
    if (trimester.length === 1) {
      const [trim] = trimester;
      trimesterstoCheck.push(trim - 1);
    } else if (trimester.length === 2) {
      const [_start, _end] = trimester;
      const start = _start - 1;
      const end = _end - 1;
      for (let i = start; i < end; i++)
        trimesterstoCheck.push(i);
    }
    for (const trim of trimesterstoCheck) {
      if (selectedDays[trim].some((day) => timeOfOccurrence.includes(day)))
        return {canBeSelected: false};
    }
  }
  return {canBeSelected: true, relatedCourses: coursesToCheck};
};
export const bindCourses = async () => {
  loading.set(true);
  const _query = query(collection(firestore, "workspaces/demo/courses"), where("public", "==", true));
  const _courses = await getDocs(_query).then((snap) => snap.docs.map((doc) => doc.data()));
  courses.set(_courses);
  loading.set(false);
};
export const unbindCourses = () => {
  unsubscribeInternal && unsubscribeInternal();
  courses.set([]);
};
