import {initializeApp, getApps} from "../_snowpack/pkg/firebase/app.js";
import {
  getFirestore
} from "../_snowpack/pkg/firebase/firestore.js";
const firebaseConfig = {
  apiKey: "AIzaSyCi6mhHGBnnIy6f8fElb-b4xaDzyK-Unus",
  authDomain: "valikkursuste-systeem.firebaseapp.com",
  databaseURL: "https://valikkursuste-systeem.firebaseio.com",
  projectId: "valikkursuste-systeem",
  storageBucket: "valikkursuste-systeem.appspot.com",
  messagingSenderId: "553721031339",
  appId: "1:553721031339:web:3182b7064945e7940025ee",
  measurementId: "G-PRV3JCT64Z"
};
if (!getApps().length) {
  initializeApp(firebaseConfig);
}
export const firestore = getFirestore();
