export class TimeHandler {
  constructor(times, days = "ETKNR".split("")) {
    this.times = times;
    this.days = days;
    this.dayToIndexMap = new Map();
    this.internalMap = new Map();
    for (let i = 0; i < this.days.length; i++)
      this.dayToIndexMap.set(days[i], i);
    for (let i = 0; i < this.times; i++) {
      this.internalMap.set(i, []);
      for (const day of this.days) {
        this.internalMap.get(i)?.push({
          time: i,
          day,
          locked: false
        });
      }
    }
  }
  getSelectedDays() {
    const result = [];
    for (let i = 0; i < this.times; i++) {
      result.push([]);
      for (const day of this.days) {
        const trim = this.internalMap.get(i);
        const idx = this.dayToIndexMap.get(day);
        if (trim && idx !== void 0 && trim[idx].locked)
          result[i].push(day);
      }
    }
    console.log(result);
    return result;
  }
  updateSlot(times, days, locked = true) {
    const _times = typeof times === "number" ? [times] : times;
    const _days = typeof days === "string" ? [days] : days;
    for (const time of _times) {
      const timeEntries = this.internalMap.get(time);
      if (!timeEntries)
        throw new Error("Invalid time to disable");
      for (const day of _days) {
        const idx = this.dayToIndexMap.get(day);
        if (idx === void 0)
          throw new Error("Invalid day to disable");
        const entry = timeEntries[idx];
        timeEntries[idx] = {
          ...entry,
          locked
        };
      }
      this.internalMap.set(time, timeEntries);
    }
  }
  getMap() {
    return this.internalMap;
  }
}
