/* src\lib\Profile.svelte generated by Svelte v3.46.3 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	destroy_each,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	select_option,
	select_value,
	set_data,
	space,
	text
} from "../../_snowpack/pkg/svelte/internal.js";

import { onMount } from '../../_snowpack/pkg/svelte.js';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[6] = list[i];
	return child_ctx;
}

// (93:12) {#each filteredClasses as filteredClass}
function create_each_block(ctx) {
	let option;
	let t_value = /*filteredClass*/ ctx[6] + "";
	let t;
	let option_value_value;

	return {
		c() {
			option = element("option");
			t = text(t_value);
			option.__value = option_value_value = /*filteredClass*/ ctx[6];
			option.value = option.__value;
		},
		m(target, anchor) {
			insert(target, option, anchor);
			append(option, t);
		},
		p(ctx, dirty) {
			if (dirty & /*filteredClasses*/ 4 && t_value !== (t_value = /*filteredClass*/ ctx[6] + "")) set_data(t, t_value);

			if (dirty & /*filteredClasses*/ 4 && option_value_value !== (option_value_value = /*filteredClass*/ ctx[6])) {
				option.__value = option_value_value;
				option.value = option.__value;
			}
		},
		d(detaching) {
			if (detaching) detach(option);
		}
	};
}

function create_fragment(ctx) {
	let div10;
	let h1;
	let t1;
	let form;
	let div8;
	let div0;
	let t4;
	let div1;
	let t7;
	let div4;
	let label2;
	let t9;
	let div3;
	let select0;
	let option0;
	let option0_value_value;
	let option1;
	let option1_value_value;
	let option2;
	let option2_value_value;
	let option3;
	let option3_value_value;
	let t14;
	let div2;
	let t15;
	let div7;
	let label3;
	let t17;
	let div6;
	let select1;
	let option4;
	let option4_value_value;
	let t19;
	let div5;
	let t20;
	let div9;
	let button;
	let mounted;
	let dispose;
	let each_value = /*filteredClasses*/ ctx[2];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			div10 = element("div");
			h1 = element("h1");
			h1.textContent = "Andmed";
			t1 = space();
			form = element("form");
			div8 = element("div");
			div0 = element("div");

			div0.innerHTML = `<label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="firstName">Eesnimi</label> 
        <input class="shadow appearance-none border border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="firstName" type="text" placeholder="Eesnimi"/>`;

			t4 = space();
			div1 = element("div");

			div1.innerHTML = `<label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="lastName">Perenimi</label> 
        <input class="shadow appearance-none border border-gray-200 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="lastNam" type="text" placeholder="Perenimi"/>`;

			t7 = space();
			div4 = element("div");
			label2 = element("label");
			label2.textContent = "Õppeaste";
			t9 = space();
			div3 = element("div");
			select0 = element("select");
			option0 = element("option");
			option0.textContent = "Vali õppeaste";
			option1 = element("option");
			option1.textContent = "I";
			option2 = element("option");
			option2.textContent = "II";
			option3 = element("option");
			option3.textContent = "III";
			t14 = space();
			div2 = element("div");
			div2.innerHTML = `<svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path></svg>`;
			t15 = space();
			div7 = element("div");
			label3 = element("label");
			label3.textContent = "Klass";
			t17 = space();
			div6 = element("div");
			select1 = element("select");
			option4 = element("option");
			option4.textContent = "Vali õppeaste";

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t19 = space();
			div5 = element("div");
			div5.innerHTML = `<svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path></svg>`;
			t20 = space();
			div9 = element("div");
			button = element("button");
			button.textContent = "Edasi";
			attr(h1, "class", "text-4xl");
			attr(div0, "class", "mb-4");
			attr(div1, "class", "mb-6");
			attr(label2, "class", "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2");
			attr(label2, "for", "year");
			option0.disabled = true;
			option0.selected = true;
			option0.__value = option0_value_value = null;
			option0.value = option0.__value;
			option1.__value = option1_value_value = 0;
			option1.value = option1.__value;
			option2.__value = option2_value_value = 1;
			option2.value = option2.__value;
			option3.__value = option3_value_value = 2;
			option3.value = option3.__value;
			attr(select0, "class", "shadow block appearance-none w-full border border-gray-200 text-gray-700 py-2 px-3 pr-8 rounded leading-tight focus:outline-none");
			attr(select0, "id", "year");
			if (/*year*/ ctx[1] === void 0) add_render_callback(() => /*select0_change_handler*/ ctx[3].call(select0));
			attr(div2, "class", "pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700");
			attr(div3, "class", "relative");
			attr(div4, "class", "w-full");
			attr(label3, "class", "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2");
			attr(label3, "for", "classNumber");
			option4.disabled = true;
			option4.selected = true;
			option4.__value = option4_value_value = null;
			option4.value = option4.__value;
			attr(select1, "class", "shadow block appearance-none w-full border border-gray-200 text-gray-700 py-2 px-3 pr-8 rounded leading-tight focus:outline-none");
			attr(select1, "id", "classNumber");
			attr(div5, "class", "pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700");
			attr(div6, "class", "relative");
			attr(div7, "class", "w-full");
			attr(div8, "class", "grid grid-cols-1 lg:grid-cols-2 gap-8");
			attr(button, "class", "bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline");
			attr(button, "type", "button");
			attr(div9, "class", "mt-8");
			attr(form, "class", "pt-6 pb-8");
			attr(div10, "class", "m-auto pt-8 px-8 w-full lg:w-3/5");
		},
		m(target, anchor) {
			insert(target, div10, anchor);
			append(div10, h1);
			append(div10, t1);
			append(div10, form);
			append(form, div8);
			append(div8, div0);
			append(div8, t4);
			append(div8, div1);
			append(div8, t7);
			append(div8, div4);
			append(div4, label2);
			append(div4, t9);
			append(div4, div3);
			append(div3, select0);
			append(select0, option0);
			append(select0, option1);
			append(select0, option2);
			append(select0, option3);
			select_option(select0, /*year*/ ctx[1]);
			append(div3, t14);
			append(div3, div2);
			append(div8, t15);
			append(div8, div7);
			append(div7, label3);
			append(div7, t17);
			append(div7, div6);
			append(div6, select1);
			append(select1, option4);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(select1, null);
			}

			append(div6, t19);
			append(div6, div5);
			append(form, t20);
			append(form, div9);
			append(div9, button);

			if (!mounted) {
				dispose = [
					listen(select0, "change", /*select0_change_handler*/ ctx[3]),
					listen(button, "click", /*click_handler*/ ctx[4])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*year*/ 2) {
				select_option(select0, /*year*/ ctx[1]);
			}

			if (dirty & /*filteredClasses*/ 4) {
				each_value = /*filteredClasses*/ ctx[2];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(select1, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div10);
			destroy_each(each_blocks, detaching);
			mounted = false;
			run_all(dispose);
		}
	};
}

const classes = 'ABCDEFGHI';

function instance($$self, $$props, $$invalidate) {
	let { done = false } = $$props;
	let year;
	const years = ['I', 'II', 'III'];
	let filteredClasses;

	onMount(() => {
		return () => {
			
		};
	});

	function select0_change_handler() {
		year = select_value(this);
		$$invalidate(1, year);
	}

	const click_handler = () => $$invalidate(0, done = true);

	$$self.$$set = $$props => {
		if ('done' in $$props) $$invalidate(0, done = $$props.done);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*year*/ 2) {
			$: $$invalidate(2, filteredClasses = classes.substr(year * 3, 3).split(''));
		}
	};

	return [done, year, filteredClasses, select0_change_handler, click_handler];
}

class Profile extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { done: 0 });
	}
}

export default Profile;