import './Loader.svelte.css';
/* src\lib\Loader.svelte generated by Svelte v3.46.3 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal
} from "../../_snowpack/pkg/svelte/internal.js";

function create_fragment(ctx) {
	let div4;

	return {
		c() {
			div4 = element("div");

			div4.innerHTML = `<div class="svelte-1umncu8"></div> 
  <div class="svelte-1umncu8"></div> 
  <div class="svelte-1umncu8"></div> 
  <div class="svelte-1umncu8"></div>`;

			attr(div4, "class", "lds-ring svelte-1umncu8");
		},
		m(target, anchor) {
			insert(target, div4, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div4);
		}
	};
}

class Loader extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default Loader;