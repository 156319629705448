/* src\lib\Course.svelte generated by Svelte v3.46.3 */
import {
	SvelteComponent,
	add_flush_callback,
	append,
	attr,
	bind,
	binding_callbacks,
	check_outros,
	create_component,
	destroy_component,
	detach,
	element,
	group_outros,
	init,
	insert,
	mount_component,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import { select } from '../store/courses.js';
import CourseDetails from './CourseDetails.svelte.js';
import ExpandButton from './ExpandButton.svelte.js';
import SelectButton from './SelectButton.svelte.js';

function create_if_block_1(ctx) {
	let p;
	let t_value = /*course*/ ctx[0].timeOfOccurrence.join(', ') + "";
	let t;

	return {
		c() {
			p = element("p");
			t = text(t_value);
			attr(p, "class", "right-0 ");
		},
		m(target, anchor) {
			insert(target, p, anchor);
			append(p, t);
		},
		p(ctx, dirty) {
			if (dirty & /*course*/ 1 && t_value !== (t_value = /*course*/ ctx[0].timeOfOccurrence.join(', ') + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

// (40:2) {#if open}
function create_if_block(ctx) {
	let coursedetails;
	let updating_course;
	let current;

	function coursedetails_course_binding(value) {
		/*coursedetails_course_binding*/ ctx[6](value);
	}

	let coursedetails_props = {};

	if (/*course*/ ctx[0] !== void 0) {
		coursedetails_props.course = /*course*/ ctx[0];
	}

	coursedetails = new CourseDetails({ props: coursedetails_props });
	binding_callbacks.push(() => bind(coursedetails, 'course', coursedetails_course_binding));

	return {
		c() {
			create_component(coursedetails.$$.fragment);
		},
		m(target, anchor) {
			mount_component(coursedetails, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const coursedetails_changes = {};

			if (!updating_course && dirty & /*course*/ 1) {
				updating_course = true;
				coursedetails_changes.course = /*course*/ ctx[0];
				add_flush_callback(() => updating_course = false);
			}

			coursedetails.$set(coursedetails_changes);
		},
		i(local) {
			if (current) return;
			transition_in(coursedetails.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(coursedetails.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(coursedetails, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div5;
	let div4;
	let div3;
	let div2;
	let div0;
	let selectbutton;
	let updating_course;
	let t0;
	let div1;
	let p;
	let t1_value = /*course*/ ctx[0].name + "";
	let t1;
	let t2;
	let t3;
	let expandbutton;
	let updating_open;
	let div4_class_value;
	let t4;
	let current;

	function selectbutton_course_binding(value) {
		/*selectbutton_course_binding*/ ctx[4](value);
	}

	let selectbutton_props = {};

	if (/*course*/ ctx[0] !== void 0) {
		selectbutton_props.course = /*course*/ ctx[0];
	}

	selectbutton = new SelectButton({ props: selectbutton_props });
	binding_callbacks.push(() => bind(selectbutton, 'course', selectbutton_course_binding));
	selectbutton.$on("trySelect", /*trySelect*/ ctx[3]);
	let if_block0 = /*course*/ ctx[0].timeOfOccurrence && create_if_block_1(ctx);

	function expandbutton_open_binding(value) {
		/*expandbutton_open_binding*/ ctx[5](value);
	}

	let expandbutton_props = {};

	if (/*open*/ ctx[1] !== void 0) {
		expandbutton_props.open = /*open*/ ctx[1];
	}

	expandbutton = new ExpandButton({ props: expandbutton_props });
	binding_callbacks.push(() => bind(expandbutton, 'open', expandbutton_open_binding));
	expandbutton.$on("toggle", /*onToggle*/ ctx[2]);
	let if_block1 = /*open*/ ctx[1] && create_if_block(ctx);

	return {
		c() {
			div5 = element("div");
			div4 = element("div");
			div3 = element("div");
			div2 = element("div");
			div0 = element("div");
			create_component(selectbutton.$$.fragment);
			t0 = space();
			div1 = element("div");
			p = element("p");
			t1 = text(t1_value);
			t2 = space();
			if (if_block0) if_block0.c();
			t3 = space();
			create_component(expandbutton.$$.fragment);
			t4 = space();
			if (if_block1) if_block1.c();
			attr(div0, "class", "flex");
			attr(div2, "class", "flex flex-row gap-2");
			attr(div3, "class", "flex w-full justify-between pr-4 lg:pr-12");

			attr(div4, "class", div4_class_value = `border rounded px-4 py-3 flex justify-between transform  transition-all ${/*course*/ ctx[0].selected
			? 'text-white border-green-700 border-opacity-50 bg-green-700 bg-opacity-50'
			: /*course*/ ctx[0].disabled
				? 'border-red-700 border-opacity-50 bg-red-700 bg-opacity-50'
				: 'border-gray-400'} ${/*open*/ ctx[1] ? 'scale-105' : 'hover:scale-105'}`);
		},
		m(target, anchor) {
			insert(target, div5, anchor);
			append(div5, div4);
			append(div4, div3);
			append(div3, div2);
			append(div2, div0);
			mount_component(selectbutton, div0, null);
			append(div2, t0);
			append(div2, div1);
			append(div1, p);
			append(p, t1);
			append(div3, t2);
			if (if_block0) if_block0.m(div3, null);
			append(div4, t3);
			mount_component(expandbutton, div4, null);
			append(div5, t4);
			if (if_block1) if_block1.m(div5, null);
			current = true;
		},
		p(ctx, [dirty]) {
			const selectbutton_changes = {};

			if (!updating_course && dirty & /*course*/ 1) {
				updating_course = true;
				selectbutton_changes.course = /*course*/ ctx[0];
				add_flush_callback(() => updating_course = false);
			}

			selectbutton.$set(selectbutton_changes);
			if ((!current || dirty & /*course*/ 1) && t1_value !== (t1_value = /*course*/ ctx[0].name + "")) set_data(t1, t1_value);

			if (/*course*/ ctx[0].timeOfOccurrence) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_1(ctx);
					if_block0.c();
					if_block0.m(div3, null);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			const expandbutton_changes = {};

			if (!updating_open && dirty & /*open*/ 2) {
				updating_open = true;
				expandbutton_changes.open = /*open*/ ctx[1];
				add_flush_callback(() => updating_open = false);
			}

			expandbutton.$set(expandbutton_changes);

			if (!current || dirty & /*course, open*/ 3 && div4_class_value !== (div4_class_value = `border rounded px-4 py-3 flex justify-between transform  transition-all ${/*course*/ ctx[0].selected
			? 'text-white border-green-700 border-opacity-50 bg-green-700 bg-opacity-50'
			: /*course*/ ctx[0].disabled
				? 'border-red-700 border-opacity-50 bg-red-700 bg-opacity-50'
				: 'border-gray-400'} ${/*open*/ ctx[1] ? 'scale-105' : 'hover:scale-105'}`)) {
				attr(div4, "class", div4_class_value);
			}

			if (/*open*/ ctx[1]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty & /*open*/ 2) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div5, null);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(selectbutton.$$.fragment, local);
			transition_in(expandbutton.$$.fragment, local);
			transition_in(if_block1);
			current = true;
		},
		o(local) {
			transition_out(selectbutton.$$.fragment, local);
			transition_out(expandbutton.$$.fragment, local);
			transition_out(if_block1);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div5);
			destroy_component(selectbutton);
			if (if_block0) if_block0.d();
			destroy_component(expandbutton);
			if (if_block1) if_block1.d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { course } = $$props;
	let open = false;

	const onToggle = () => {
		$$invalidate(1, open = !open);
	};

	const trySelect = () => {
		select(course.id);
	};

	function selectbutton_course_binding(value) {
		course = value;
		$$invalidate(0, course);
	}

	function expandbutton_open_binding(value) {
		open = value;
		$$invalidate(1, open);
	}

	function coursedetails_course_binding(value) {
		course = value;
		$$invalidate(0, course);
	}

	$$self.$$set = $$props => {
		if ('course' in $$props) $$invalidate(0, course = $$props.course);
	};

	return [
		course,
		open,
		onToggle,
		trySelect,
		selectbutton_course_binding,
		expandbutton_open_binding,
		coursedetails_course_binding
	];
}

class Course extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { course: 0 });
	}
}

export default Course;