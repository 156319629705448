/* src\lib\CourseDetails.svelte generated by Svelte v3.46.3 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	space,
	text
} from "../../_snowpack/pkg/svelte/internal.js";

function create_if_block(ctx) {
	let p;
	let b;
	let t1;
	let t2_value = /*course*/ ctx[0].notes + "";
	let t2;

	return {
		c() {
			p = element("p");
			b = element("b");
			b.textContent = "Märkused:";
			t1 = space();
			t2 = text(t2_value);
		},
		m(target, anchor) {
			insert(target, p, anchor);
			append(p, b);
			append(p, t1);
			append(p, t2);
		},
		p(ctx, dirty) {
			if (dirty & /*course*/ 1 && t2_value !== (t2_value = /*course*/ ctx[0].notes + "")) set_data(t2, t2_value);
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let p0;
	let t0_value = /*course*/ ctx[0].description + "";
	let t0;
	let t1;
	let p1;
	let b0;
	let t3;
	let t4_value = /*course*/ ctx[0].trimester.join(', ') + "";
	let t4;
	let t5;
	let t6_value = ' ' + "";
	let t6;
	let t7;
	let t8_value = /*course*/ ctx[0].timeOfOccurrence.join(' ja ') + "";
	let t8;
	let t9;
	let t10;
	let p2;
	let b1;
	let t12;
	let t13_value = /*course*/ ctx[0].teachers + "";
	let t13;
	let if_block = /*course*/ ctx[0].notes && create_if_block(ctx);

	return {
		c() {
			div = element("div");
			p0 = element("p");
			t0 = text(t0_value);
			t1 = space();
			p1 = element("p");
			b0 = element("b");
			b0.textContent = "Toimumisaeg:";
			t3 = space();
			t4 = text(t4_value);
			t5 = text(" trimester");
			t6 = text(t6_value);
			t7 = space();
			t8 = text(t8_value);
			t9 = space();
			if (if_block) if_block.c();
			t10 = space();
			p2 = element("p");
			b1 = element("b");
			b1.textContent = "Õpetajad:";
			t12 = space();
			t13 = text(t13_value);
			attr(p0, "class", "");
			attr(div, "class", "border border-gray-400 rounded mb-2 p-4 text-gray-600 flex flex-col gap-1");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, p0);
			append(p0, t0);
			append(div, t1);
			append(div, p1);
			append(p1, b0);
			append(p1, t3);
			append(p1, t4);
			append(p1, t5);
			append(p1, t6);
			append(p1, t7);
			append(p1, t8);
			append(div, t9);
			if (if_block) if_block.m(div, null);
			append(div, t10);
			append(div, p2);
			append(p2, b1);
			append(p2, t12);
			append(p2, t13);
		},
		p(ctx, [dirty]) {
			if (dirty & /*course*/ 1 && t0_value !== (t0_value = /*course*/ ctx[0].description + "")) set_data(t0, t0_value);
			if (dirty & /*course*/ 1 && t4_value !== (t4_value = /*course*/ ctx[0].trimester.join(', ') + "")) set_data(t4, t4_value);
			if (dirty & /*course*/ 1 && t8_value !== (t8_value = /*course*/ ctx[0].timeOfOccurrence.join(' ja ') + "")) set_data(t8, t8_value);

			if (/*course*/ ctx[0].notes) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, t10);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*course*/ 1 && t13_value !== (t13_value = /*course*/ ctx[0].teachers + "")) set_data(t13, t13_value);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			if (if_block) if_block.d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { course } = $$props;

	$$self.$$set = $$props => {
		if ('course' in $$props) $$invalidate(0, course = $$props.course);
	};

	return [course];
}

class CourseDetails extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { course: 0 });
	}
}

export default CourseDetails;