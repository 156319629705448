/* src\Courses.svelte generated by Svelte v3.46.3 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import { onMount } from '../_snowpack/pkg/svelte.js';
import courses, { loading, bindCourses, unbindCourses } from './store/courses.js';
import Course from './lib/Course.svelte.js';
import Loader from './lib/Loader.svelte.js';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[2] = list[i];
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[2] = list[i];
	return child_ctx;
}

function get_each_context_2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[2] = list[i];
	return child_ctx;
}

// (42:0) {:else}
function create_else_block(ctx) {
	let div1;
	let div0;
	let loader;
	let current;
	loader = new Loader({});

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			create_component(loader.$$.fragment);
			attr(div0, "class", "m-auto");
			attr(div1, "class", "w-full h-screen flex");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			mount_component(loader, div0, null);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(loader.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(loader.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			destroy_component(loader);
		}
	};
}

// (13:0) {#if !$loading}
function create_if_block(ctx) {
	let div4;
	let h10;
	let t1;
	let div0;
	let t2;
	let h11;
	let t4;
	let div1;
	let t5;
	let h12;
	let t7;
	let div2;
	let t8;
	let div3;
	let current;
	let each_value_2 = /*$courses*/ ctx[1].filter(func);
	let each_blocks_2 = [];

	for (let i = 0; i < each_value_2.length; i += 1) {
		each_blocks_2[i] = create_each_block_2(get_each_context_2(ctx, each_value_2, i));
	}

	const out = i => transition_out(each_blocks_2[i], 1, 1, () => {
		each_blocks_2[i] = null;
	});

	let each_value_1 = /*$courses*/ ctx[1].filter(func_1);
	let each_blocks_1 = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks_1[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	const out_1 = i => transition_out(each_blocks_1[i], 1, 1, () => {
		each_blocks_1[i] = null;
	});

	let each_value = /*$courses*/ ctx[1].filter(func_2);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out_2 = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div4 = element("div");
			h10 = element("h1");
			h10.textContent = "1. trimestril toimuvad kursused";
			t1 = space();
			div0 = element("div");

			for (let i = 0; i < each_blocks_2.length; i += 1) {
				each_blocks_2[i].c();
			}

			t2 = space();
			h11 = element("h1");
			h11.textContent = "2. trimestril toimuvad kursused";
			t4 = space();
			div1 = element("div");

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].c();
			}

			t5 = space();
			h12 = element("h1");
			h12.textContent = "3. trimestril toimuvad kursused";
			t7 = space();
			div2 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t8 = space();
			div3 = element("div");
			div3.innerHTML = `<button class="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">Esita</button>`;
			attr(h10, "class", "text-3xl mb-4");
			attr(div0, "class", "flex flex-col gap-1");
			attr(h11, "class", "text-3xl mt-4 mb-4");
			attr(div1, "class", "flex flex-col gap-1");
			attr(h12, "class", "text-3xl mt-4 mb-4");
			attr(div2, "class", "flex flex-col gap-1");
			attr(div3, "class", "my-8");
			attr(div4, "class", "mx-auto w-full lg:w-3/5 pt-8 px-8");
		},
		m(target, anchor) {
			insert(target, div4, anchor);
			append(div4, h10);
			append(div4, t1);
			append(div4, div0);

			for (let i = 0; i < each_blocks_2.length; i += 1) {
				each_blocks_2[i].m(div0, null);
			}

			append(div4, t2);
			append(div4, h11);
			append(div4, t4);
			append(div4, div1);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].m(div1, null);
			}

			append(div4, t5);
			append(div4, h12);
			append(div4, t7);
			append(div4, div2);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div2, null);
			}

			append(div4, t8);
			append(div4, div3);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*$courses*/ 2) {
				each_value_2 = /*$courses*/ ctx[1].filter(func);
				let i;

				for (i = 0; i < each_value_2.length; i += 1) {
					const child_ctx = get_each_context_2(ctx, each_value_2, i);

					if (each_blocks_2[i]) {
						each_blocks_2[i].p(child_ctx, dirty);
						transition_in(each_blocks_2[i], 1);
					} else {
						each_blocks_2[i] = create_each_block_2(child_ctx);
						each_blocks_2[i].c();
						transition_in(each_blocks_2[i], 1);
						each_blocks_2[i].m(div0, null);
					}
				}

				group_outros();

				for (i = each_value_2.length; i < each_blocks_2.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			if (dirty & /*$courses*/ 2) {
				each_value_1 = /*$courses*/ ctx[1].filter(func_1);
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks_1[i]) {
						each_blocks_1[i].p(child_ctx, dirty);
						transition_in(each_blocks_1[i], 1);
					} else {
						each_blocks_1[i] = create_each_block_1(child_ctx);
						each_blocks_1[i].c();
						transition_in(each_blocks_1[i], 1);
						each_blocks_1[i].m(div1, null);
					}
				}

				group_outros();

				for (i = each_value_1.length; i < each_blocks_1.length; i += 1) {
					out_1(i);
				}

				check_outros();
			}

			if (dirty & /*$courses*/ 2) {
				each_value = /*$courses*/ ctx[1].filter(func_2);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div2, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out_2(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value_2.length; i += 1) {
				transition_in(each_blocks_2[i]);
			}

			for (let i = 0; i < each_value_1.length; i += 1) {
				transition_in(each_blocks_1[i]);
			}

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks_2 = each_blocks_2.filter(Boolean);

			for (let i = 0; i < each_blocks_2.length; i += 1) {
				transition_out(each_blocks_2[i]);
			}

			each_blocks_1 = each_blocks_1.filter(Boolean);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				transition_out(each_blocks_1[i]);
			}

			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div4);
			destroy_each(each_blocks_2, detaching);
			destroy_each(each_blocks_1, detaching);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (17:6) {#each $courses.filter(_course => _course.tag === 'I_TRIM') as course}
function create_each_block_2(ctx) {
	let course;
	let current;
	course = new Course({ props: { course: /*course*/ ctx[2] } });

	return {
		c() {
			create_component(course.$$.fragment);
		},
		m(target, anchor) {
			mount_component(course, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const course_changes = {};
			if (dirty & /*$courses*/ 2) course_changes.course = /*course*/ ctx[2];
			course.$set(course_changes);
		},
		i(local) {
			if (current) return;
			transition_in(course.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(course.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(course, detaching);
		}
	};
}

// (23:6) {#each $courses.filter(_course => _course.tag === 'II_TRIM') as course}
function create_each_block_1(ctx) {
	let course;
	let current;
	course = new Course({ props: { course: /*course*/ ctx[2] } });

	return {
		c() {
			create_component(course.$$.fragment);
		},
		m(target, anchor) {
			mount_component(course, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const course_changes = {};
			if (dirty & /*$courses*/ 2) course_changes.course = /*course*/ ctx[2];
			course.$set(course_changes);
		},
		i(local) {
			if (current) return;
			transition_in(course.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(course.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(course, detaching);
		}
	};
}

// (29:6) {#each $courses.filter(_course => _course.tag === 'III_TRIM') as course}
function create_each_block(ctx) {
	let course;
	let current;
	course = new Course({ props: { course: /*course*/ ctx[2] } });

	return {
		c() {
			create_component(course.$$.fragment);
		},
		m(target, anchor) {
			mount_component(course, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const course_changes = {};
			if (dirty & /*$courses*/ 2) course_changes.course = /*course*/ ctx[2];
			course.$set(course_changes);
		},
		i(local) {
			if (current) return;
			transition_in(course.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(course.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(course, detaching);
		}
	};
}

function create_fragment(ctx) {
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (!/*$loading*/ ctx[0]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(if_block_anchor.parentNode, if_block_anchor);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if_blocks[current_block_type_index].d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

const func = _course => _course.tag === 'I_TRIM';
const func_1 = _course => _course.tag === 'II_TRIM';
const func_2 = _course => _course.tag === 'III_TRIM';

function instance($$self, $$props, $$invalidate) {
	let $loading;
	let $courses;
	component_subscribe($$self, loading, $$value => $$invalidate(0, $loading = $$value));
	component_subscribe($$self, courses, $$value => $$invalidate(1, $courses = $$value));

	onMount(() => {
		bindCourses();

		return () => {
			unbindCourses();
		};
	});

	return [$loading, $courses];
}

class Courses extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Courses;