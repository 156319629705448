import './App.svelte.css';
/* src\App.svelte generated by Svelte v3.46.3 */
import {
	SvelteComponent,
	add_flush_callback,
	bind,
	binding_callbacks,
	check_outros,
	create_component,
	destroy_component,
	detach,
	element,
	group_outros,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import Courses from './Courses.svelte.js';
import Profile from './lib/Profile.svelte.js';

function create_else_block(ctx) {
	let courses;
	let current;
	courses = new Courses({});

	return {
		c() {
			create_component(courses.$$.fragment);
		},
		m(target, anchor) {
			mount_component(courses, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(courses.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(courses.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(courses, detaching);
		}
	};
}

// (7:2) {#if !profileDone}
function create_if_block(ctx) {
	let profile;
	let updating_done;
	let current;

	function profile_done_binding(value) {
		/*profile_done_binding*/ ctx[1](value);
	}

	let profile_props = {};

	if (/*profileDone*/ ctx[0] !== void 0) {
		profile_props.done = /*profileDone*/ ctx[0];
	}

	profile = new Profile({ props: profile_props });
	binding_callbacks.push(() => bind(profile, 'done', profile_done_binding));

	return {
		c() {
			create_component(profile.$$.fragment);
		},
		m(target, anchor) {
			mount_component(profile, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const profile_changes = {};

			if (!updating_done && dirty & /*profileDone*/ 1) {
				updating_done = true;
				profile_changes.done = /*profileDone*/ ctx[0];
				add_flush_callback(() => updating_done = false);
			}

			profile.$set(profile_changes);
		},
		i(local) {
			if (current) return;
			transition_in(profile.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(profile.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(profile, detaching);
		}
	};
}

function create_fragment(ctx) {
	let main;
	let current_block_type_index;
	let if_block;
	let current;
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (!/*profileDone*/ ctx[0]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			main = element("main");
			if_block.c();
		},
		m(target, anchor) {
			insert(target, main, anchor);
			if_blocks[current_block_type_index].m(main, null);
			current = true;
		},
		p(ctx, [dirty]) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(main, null);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(main);
			if_blocks[current_block_type_index].d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let profileDone = false;

	function profile_done_binding(value) {
		profileDone = value;
		$$invalidate(0, profileDone);
	}

	return [profileDone, profile_done_binding];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;